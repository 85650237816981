import React from 'react'
import './sliding-window.css'

function SlidingWindow({isVisible, children}) {
  return (
    <div className={`sliding-window ${isVisible ? 'visible' : ''}`}>
      {children}
    </div>
  )
}

export default SlidingWindow
